<template>
  <div>
    <div class="cases">
      <div class="product_title">
        <div class="product_tit">
          <div class="tit1">医教产品</div>
          <div class="tit2"></div>
        </div>
      </div>
      <div class="sportswiper">
        <swiper ref="numberSwiper" :options="swiperOption" id="myswiper">
          <swiper-slide v-for="(item,index) in casearr" :key="index">
            <div class="swiper_con" @click="routerlink(item.articleId,index)">
              <img :src="item.articleCover" alt="" />
              <span>{{item.articleTitle}}</span>
            </div>
          </swiper-slide>

          <div class="swiper-pagination my_bullet" slot="pagination"></div>
          <!-- <div class="swiper-button-prev" slot="button-prev"><img  src="../assets/images/01首页/上一页1.png" alt=""></div>
        <div class="swiper-button-next" slot="button-next"><img src="../assets/images/01首页/下一页1.png" alt=""></div> -->
        </swiper>
        <div class="swiper-list">
            <div class="swiper-button-prev-diy" @click="numberPrevFn"></div>
        <div class="swiper-button-next-diy" @click="nmuberNextFn"></div>
        </div>
      
      </div>
      <!-- <div class="caselist">

        <ul>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/住院医师规范化培训管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">住院医师规范化培训管理系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/临床技能中心综合管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床技能中心综合管理系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/临床胜任力训练与考核系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床胜任力训练与考核系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/临床思维训练系统.png"
                alt=""
              />
            </div>
            <div class="casetit">临床思维训练系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/医学在线考试系统.png"
                alt=""
              />
            </div>
            <div class="casetit">医学在线考试系统</div>
          </li>
          <li>
            <div class="caseimg">
              <img
                src="../../../assets/images/05数字医教/医教信息化/OSCE考核管理系统.png"
                alt=""
              />
            </div>
            <div class="casetit">OSCE考核管理系统</div>
          </li>
        </ul>
      </div> -->
    </div>
  </div>
</template>

<script>
import { getbannerdetail } from "@/http/api/user.js";
export default {
  data() {
    return {
      casearr: [],
      // 轮播
      swiperOption: {
        slidesPerView: 1,
        spaceBetween: 30,
        slidesPerGroup: 1,

        slidesPerColumn: 3,
        slidesPerColumnFill: "row",
        // 设置分页器
        pagination: {
          el: ".swiper-pagination",
          // bulletClass: "my-bullet",
          // 设置点击可切换
          clickable: true,
        },
        // 设置前进后退按钮
        navigation: {
          nextEl: ".sportswiper .swiper-button-next",
          prevEl: ".sportswiper .swiper-button-prev",
        },
        // 设置自动轮播
        // autoplay: {
        //   delay: 3000, // 5秒切换一次
        // },
        // // 设置轮播可循环
        // loop: true,
      },
    };
  },
  computed: {
    // 医疗产品
    numberSwiper() {
      return this.$refs.numberSwiper.$swiper;
    },
  },
  mounted() {
    this.getcompany();
  },
  methods: {
    //获取医疗产品
    async getcompany() {
      let params = {
        barId: 7,
      };
      const data = await getbannerdetail(params);
      this.casearr = data.data.children[0].portalArticleList;
      this.casearr.forEach((r) => {
        r.articleCover = "http://36.152.65.166:10008" + r.articleCover;
      });
    },
    //医教产品分页
    numberPrevFn() {
      this.numberSwiper.slidePrev();
    },
    nmuberNextFn() {
      this.numberSwiper.slideNext();
    },
    // 医教产品详情
    routerlink(id, index) {
      this.$router.push({
        path: `/m_medicaleducation/infodetail`,
        query: {
          id: id,
          index: index,
        },
      });
    },
  },
};
</script>

<style>
</style>
<style lang="scss" scoped>
.my_bullet {
  height: 4% !important;
  bottom: -5% !important;
}
.cases {
    height: calc(100vh - 270px);
  // border: solid;
  .sportswiper {
    // margin-top: 0.5rem;
    width: 7rem;
  
    overflow: hidden;
    margin: 1rem auto;
    position: relative;
    .swiper-button-prev-diy,
    .swiper-button-next-diy {
      width: 0.5rem;
      height: 0.5rem;
      position: absolute;
      bottom: 0px;
      transform: translate(0, -50%);
      -webkit-transform: translate(0, -50%);
    }
    .swiper-button-prev-diy {
      left: 1.5rem;

      z-index: 9999;
      background: url("~@/assets/images/01首页/上一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-prev-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/上一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy:hover {
      cursor: pointer;
      background: url("~@/assets/images/01首页/下一页2.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper-button-next-diy {
      z-index: 9999;
      right: 1.5rem;
      background: url("~@/assets/images/01首页/下一页1.png") no-repeat;
      background-size: 100% 100%;
    }
    .swiper_con {
      width: 100%;
      height: 5.4rem;
      border: 0.02rem solid #0088ea;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 0.1rem;
      img {
        width: 100%;
      }
      span {
        height: 0.85rem;
        line-height: 0.85rem;
        font-size: 0.18rem;
        color: #000000;
      }
    }
  }
  // .caselist {
  //   width: 7.2rem;
  //   margin: 0 auto;
  //   ul {
  //     width: 100%;
  //     margin-bottom: 1rem;
  //     margin-top: 0.5rem;
  //     display: flex;
  //     padding: 0;
  //     flex-wrap: wrap;
  //     justify-content: space-between;
  //     li {
  //       height: 3.27rem;
  //       width: 3.46rem;
  //       background-color: #e8eaf2;
  //       text-align: center;

  //       margin-top: 0.3rem;

  //       .casetit {
  //         height: 0.8rem;
  //         line-height: 0.8rem;
  //         font-size: 0.2rem;
  //       }
  //       .caseimg {
  //         width: 100%;
  //         height: 2.6rem;
  //         box-sizing: border-box;
  //         border: 0.01rem solid gray;
  //         z-index: 222;
  //         img {
  //           width: 100%;
  //           height: 100%;
  //           box-sizing: border-box;
  //           border: 0.01rem solid white;
  //           z-index: 1;
  //         }
  //       }
  //     }
  //     li:hover {
  //       cursor: pointer;
  //       .casetit {
  //         background-color: #0088ea;
  //         color: white;
  //         font-weight: bold;
  //       }
  //     }
  //   }
  // }
}
.product_title {
  width: 7.5rem;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .product_tit {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    .tit1 {
      font-size: 0.3rem;
      font-weight: bold;
      margin-bottom: 0.13rem;
    }
    .tit2 {
      width: 0.6rem;
      height: 0.04rem;
      background-color: #0088ea;
    }
  }
}
</style>